import Server from '@/apis'
export default class extends Server {
  /**
   *获取角色列表
   * @param {*} opt
   */
  getRoles (opt) {
    return this.$http.get(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/roles`, { params: Server.clearNull(opt) })
  }

  /**
   *获取角色详情
   * @param {*} roleId
   */
  getRole (roleId) {
    return this.$http.get(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/role/${roleId}`)
  }

  /**
   *新增角色
   * @param {*} opt
   */
  addRole (opt) {
    return this.$http.post(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/role`, opt)
  }

  /**
   *修改角色
   * @param {*} roleId
   * @param {*} opt
   */
  putRole (roleId, opt) {
    return this.$http.put(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/role/${roleId}`, Server.clearAllNull(opt))
  }

  /**
   *删除角色
   * @param {*} roleId
   * @param {*} cb
   */
  deleteRole (roleId, cb) {
    this.$http.delete(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/role/${roleId}`).then(function (res) {
      cb(res)
    })
  }

  /**
   *获取操作列表
   */
  getOperations () {
    return this.$http.get(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/operations`, { params: { ownPort: 0 }})
  }

  /**
   *获取机构列表
   * @param {*} opt
   */
  getOrgans (opt) {
    return this.$http.get(Server.path.apiCrmOS + `partner/${window.global._auth.userInfo.partnerId}/organs`, { params: Server.clearNull(opt) })
  }
}
