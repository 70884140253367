<style lang="less">
.mod-role-manage{
  padding: 20px;
  background: #fff;
  .jw-table .filter{
    float: right;
    zoom: 1;
    .find{
      display: inline;
    }
  }
  .role-form{
    padding: 40px 20px 0;
    .el-radio-group .el-radio:first-child{
      margin-right: 50px;
    }
  }
  .mod-tree{
    width: 460px;
    height: 360px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #e9eef7;
    overflow-y: auto;
  }
  .jw-form .el-form-item__content > .el-input{
    width: 460px;
  }
  .jw-form .el-form-item__content > .el-select{
    width: 460px;
  }
  .jw-form .warn-info{
    margin-left: 20px;
    padding: 10px 20px 10px 10px;
    background: #ebeef5;
    color: #989797;
    position: relative;
    &::before{
      content: "\e61a";
      display: block;
      font-size: 18px;
      padding: 0 10px;
      color: #E6A23C;
      transition: color .15s linear;
      font-family: element-icons!important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: baseline;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
</style>
<template>
	<div class="mod-role-manage">
		<jw-table ref="table" v-bind="table" @search="search" />
		<jw-full-screen-dialog
			ref="role"
			:before-close="beforeClose"
			:query-names="queryNames"
			:title="$route.query.title || ''"
			:keep-render="false"
			:old-style="true"
			>
			<jw-form ref="form" class="role-form" v-bind="editForm" />
			<div slot="footer">
				<el-button size="small" @click="closeDialog">取消</el-button>
				<el-button :loading="isLoading" type="primary" size="small" @click="sureDialog">保存</el-button>
			</div>
		</jw-full-screen-dialog>
	</div>
</template>
<script lang="jsx">
import Server from './apis'
import moment from 'moment'

export default {
  name: 'CrmRoleManage',
  components: {
  },
  props: {},
  data () {
    const self = this
    return {
      queryNames: ['roleId'],
      isLoading: false,
      title: '',
      table: {
        data: [],
        hasclear: false,
        page: {
          total: 0,
          setSize: 20
        },
        filters: [
          {
            type: 'text',
            prop: 'roleName',
            value: '',
            bind: {
              attrs: {
                placeholder: '请输入角色名称'
              }
            }
          }
        ],
        btns: [
          {
            label: '新增角色',
            hide: !this.permission('d8b95da2-7f63-413e-b65d-8d2fc196fb33'),
            bind: {
              props: {
                disabled: false
              }
            },
            click: () => {
              this.checkedKeys = []
              this.editForm.bind.props.rules.roleName.required = true
              this.$refs.role.open({
                roleId: null,
                isAdd: 1,
                title: '新增角色',
                roleName: null
              })
              this.getOperations()
              // this.getOrgans()
            }
          }],
        cols: [
          {
            label: '角色名称',
            prop: 'roleName',
            minWidth: 180
          },
          {
            label: '关联人数',
            prop: 'linkEmpNum',
            minWidth: 100
          },
          {
            label: '创建时间',
            prop: 'gmtCreate',
            minWidth: 170,
            sort: true,
            fuc: (row, h) => {
              return moment(row.gmtCreate).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            label: '最新更新时间',
            prop: 'gmtModified',
            minWidth: 170,
            sort: true,
            fuc: (row, h) => {
              return moment(row.gmtModified).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            label: '状态',
            prop: 'isValid',
            width: 80,
            filterMultiple: false,
            filters: [{ text: '全部', value: '' }, { text: '停用', value: 0 }, { text: '启用', value: 1 }],
            fuc: (row, h) => {
              return { type: 'text', value: ['停用', '启用'][row.isValid] || '' }
            }
          },
          {
            label: '操作',
            width: 120,
            bind: {
              props: {
                fixed: 'right'
              }
            },
            fuc: (row, h, index, i) => {
              const btn = [
                {
                  label: '编辑',
                  no: !this.permission('a600984a-4843-4b53-9667-0db780836b6d'),
                  click: () => {
                    this.checkedKeys = []
                    this.editForm.bind.props.rules.roleName.required = false
                    this.$refs.role.open({
                      roleId: row.roleId,
                      isAdd: 0,
                      title: '编辑角色',
                      roleName: row.roleName
                    })
                    this.getOperations()
                    // this.getOrgans()
                    // this.getRole(row.roleId)
                  }
                },
                {
                  label: '删除',
                  no: !!row.linkEmpNum,
                  hide: !this.permission('B0C997C4-7A99-47EC-A183-21CB1255AB0E') || !!row.isAdmin,
                  click: () => {
                    self.$confirm('删除后无法恢复，您确定要删除吗？', '删除', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      customClass: 'dialog-mod-employee'
                    }).then(() => {
                      self.$apis.deleteRole(row.roleId, (data) => {
                        self.$message.success('删除成功')
                        self.$refs.table.$search()
                      })
                    }).catch(() => {})
                  }
                }
              ]
              return row.roleKey ? '-' : {
                type: 'btns',
                btns: btn
              }
            }
          }
        ],
        currentPage: 1
      },
      roleList: [],
      checkedKeys: [],
      allKays: [],
      isWarn: false,
      editForm: {
        read: false,
        bind: {
          props: {
            labelWidth: '250px',
            rules: {
              roleName: {
                required: true,
                validator: function (rule, value, callback) {
                  const roleName = self.$refs.form.$getValue().roleName || ''
                  if (roleName.length > 20) {
                    callback(new Error('字数超限'))
                  } else if (!roleName) {
                    callback(new Error('不能为空！'))
                  } else {
                    callback()
                  }
                }
              },
              partnerId: {
                required: true,
                validator: function (rule, value, callback) {
                  if (value) {
                    callback()
                  } else {
                    callback(new Error('不能为空！'))
                  }
                }
              },
              isValid: {
                required: true,
                validator: function (rule, value, callback) {
                  if (value) {
                    callback()
                  } else {
                    callback(new Error('不能为空！'))
                  }
                }
              },
              opId: {
                required: true,
                trigger: 'blur',
                type: 'array',
                validator: function (rule, value, callback) {
                  const opid = self.$refs.form.$getValue().opId
                  if (opid.length) {
                    callback()
                  } else {
                    callback(new Error('菜单授权不能为空！'))
                  }
                }
              }
            }
          }
        },
        data: [
          {
            label: '角色名称',
            prop: 'roleName',
            render (h, store, value) {
              return <el-input placeholder='请输入角色名称' value={this.$route.query.roleName} on-input={(e) => { self.$refs.form.$setValueField('roleName', e) }} />
            }
          },
          // {
          //   tag: 'el-select',
          //   label: '所属机构',
          //   prop: 'partnerId',
          //   options: [],
          //   bind: {
          //     props: {
          //       'filterable': true,
          //       placeholder: '请选择所属机构'
          //     },
          //     on: {
          //       change (value) {
          //         self.$refs.form.$setValueField('partnerId', value)
          //       }
          //     }
          //   }
          // },
          {
            tag: 'el-radio-group',
            label: '状态',
            write: true,
            prop: 'isValid',
            options: [
              {
                value: '1',
                label: '启用'
              },
              {
                value: '0',
                label: '停用',
                bind: {
                  props: {
                    disabled: false
                  }
                }
              }
            ],
            render (h) {
              return self.isWarn ? <span class='warn-info'>已有员工关联了该角色，前往“员工管理”解除员工与该角色关系，才能做停用操作！</span> : null
            }
          },
          {
            label: '菜单权限',
            prop: 'opId',
            render (h, store, value) {
              const defaultProps = {
                children: 'children',
                label: 'label'
              }
              return <div class='mod-tree'><el-tree
                data={self.roleList}
                show-checkbox={true}
                empty-text={'正在加载中...'}
                default-expand-all={false}
                default-expanded-keys={self.roleList.map(item => { return item.opId })}
                node-key={'opId'}
                ref={'tree'}
                on-check={(checkedNode, checkedKeys, halfCheckedNodes, halfCheckedKeys) => {
                  self.$refs.form.$setValueField('opId', checkedKeys.checkedKeys.concat(checkedKeys.halfCheckedKeys))
                }}
                default-checked-keys={self.checkedKeys}
                highlight-current={true}
                props={defaultProps}>
              </el-tree></div>
            }
          }
        ],
        value: {
          roleName: null,
          partnerId: null,
          isValid: '1',
          opId: []
        }
      }
    }
  },
  beforeCreate () {
    this.$apis = new Server(this.$axios, this.$message.warning)
  },
  mounted () {
    if (this.$route.query.title) {
      this.getOperations()
      // this.getOrgans()
    }
    // if (this.$route.query.title && this.$route.query.roleId) {
    //  this.getRole(this.$route.query.roleId)
    // }
    this.editForm.bind.props.rules.roleName.required = !!Number(this.$route.query.isAdd)
    this.$refs.table.$search()
  },
  methods: {
    search (filters, selecttion) {
      const dataMap = {
        descending: 'desc',
        ascending: 'asc'
      }
      const opt = {
        ownPort: 0,
        partnerId: this._auth.userInfo.partnerId,
        pageNum: filters.page || 1,
        pageSize: filters.size || 20,
        roleName: filters.filters[0].value,
        isValid: selecttion.isValid ? selecttion.isValid[0] : null,
        sort: Object.keys(filters.sort).length ? decodeURIComponent(`{"${Object.keys(filters.sort)[0]}":"${dataMap[Object.values(filters.sort)[0]]}"}`) : decodeURIComponent('{"gmtCreate":"desc"}')
      }
      this.$apis.getRoles(opt).then(res => {
        this.table.data = res.data.data
        this.table.page = {
          page: res && res.data && res.data.page && res.data.page.page,
          size: res && res.data && res.data.page && res.data.page.size,
          total: res && res.data && res.data.page && res.data.page.totalCount
        }
      }).catch(({ response }) => {
        this.table.data = []
        if (!(response && response.headers['x-response-by'] === 'gateway')) {
          this.$message({
            type: 'error',
            message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
              return item.message
            }).join(',')))) || '网络异常，请稍后再试'
          })
        }
      })
    },
    getOperations () {
      this.$apis.getOperations().then(res => {
        this.roleList = this.arry2tree(res.data, 0)
        this.$route.query.roleId && this.getRole(this.$route.query.roleId)
      }).catch(({ response }) => {
        if (!(response && response.headers['x-response-by'] === 'gateway')) {
          this.$message({
            type: 'error',
            message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
              return item.message
            }).join(',')))) || '网络异常，请稍后再试'
          })
        }
      })
    },
    getOrgans () {
      this.$apis.getOrgans({ pageSize: 9999, pageNum: 1 }).then(res => {
        this.editForm.data[1].options = res.data.data.map(item => {
          return {
            label: item.organName,
            value: String(item.partnerId)
          }
        })
      }).catch(({ response }) => {
        if (!(response && response.headers['x-response-by'] === 'gateway')) {
          this.$message({
            type: 'error',
            message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
              return item.message
            }).join(',')))) || '网络异常，请稍后再试'
          })
        }
      })
    },
    filterLastId (tree) {
      tree.forEach(item => {
        if (!item.children) {
          this.allKays.push(item.opId)
        } else {
          this.filterLastId(item.children)
        }
      })
    },
    getRole (roleId) {
      this.$apis.getRole(roleId).then(res => {
        this.$nextTick(() => {
          this.filterLastId(this.roleList)
          this.checkedKeys = this.allKays.filter(item => {
            return res.data.opId.indexOf(item) > -1
          })
          this.$refs.form.$setValue({
            partnerId: String(res.data.partnerId),
            roleName: res.data.roleName,
            isValid: String(res.data.isValid),
            opId: res.data.opId
          })
          this.editForm.data[1].options[1].bind.props.disabled = !!res.data.isBand
          this.isWarn = !!res.data.isBand
        })
      }).catch(({ response }) => {
        if (!(response && response.headers['x-response-by'] === 'gateway')) {
          this.$message({
            type: 'error',
            message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
              return item.message
            }).join(',')))) || '网络异常，请稍后再试'
          })
        }
      })
    },
    arry2tree (data, parentId) {
      const tree = []
      let temp
      for (let i = 0; i < data.length; i++) {
        data[i].label = data[i].opName
        if (data[i].parentId === parentId) {
          var obj = data[i]
          temp = this.arry2tree(data, data[i].opId)
          if (temp.length > 0) {
            obj.children = temp
          }
          tree.push(obj)
        }
      }
      return tree
    },
    beforeClose (close) {
      this.editForm.data[1].options[1].bind.props.disabled = false
      this.isWarn = false
      close()
    },
    sureDialog () {
      this.$refs.form.$validate(valid => {
        if (valid) {
          this.isLoading = true
          const data = this.$refs.form.$getValue();
          (Number(this.$route.query.isAdd) ? this.$apis.addRole({
            roleName: data.roleName,
            partnerId: Number(this._auth.userInfo.partnerId),
            isValid: Number(data.isValid),
            opId: data.opId,
            ownPort: 0
          }) : this.$apis.putRole(this.$route.query.roleId, {
            roleName: data.roleName,
            partnerId: Number(this._auth.userInfo.partnerId),
            isValid: Number(data.isValid),
            opId: data.opId,
            ownPort: 0
          })).then(res => {
            this.$message.success(res.data.message || '已保存')
            this.closeDialog()
          }).catch(({ response }) => {
            if (!(response && response.headers['x-response-by'] === 'gateway')) {
              this.$message({
                type: 'error',
                message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
                  return item.message
                }).join(',')))) || '网络异常，请稍后再试'
              })
            }
          }).finally(() => {
            this.isLoading = false
            this.$refs.table.$search()
          })
        }
      })
    },
    closeDialog () {
      this.editForm.data[1].options[1].bind.props.disabled = false
      this.isWarn = false
      this.$router.go(-1)
    }
  }
}
</script>
